document.addEventListener('DOMContentLoaded', function () {

  let scrollpos = window.scrollY
  const header = document.getElementById("header");
  const header_height = header.offsetHeight

  const add_fadeIn = () => header.classList.add("fade-in", "banded");
  const add_fadeOut = () => header.classList.add("fade-out");
  const add_loose = () => header.classList.add("loose");
  const remove_fadeIn = () => header.classList.remove("fade-in", "banded");
  const remove_fadeOut = () => header.classList.remove("fade-out");
  const remove_loose = () => header.classList.remove("loose");

  if (document.body.classList.contains('home')){
    window.addEventListener('scroll', function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_fadeIn()
        remove_fadeOut()
        remove_loose()
      } else {
        remove_fadeIn()
        add_fadeOut()
        add_loose()
      }
    });
  };

}, false);